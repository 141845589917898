




























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OfferPayload } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import { OfferType } from '@/types/offers';

@Component({
  components: {
    MButton: () => import('@/shared/components/MButton.vue'),
    RadioInputBox: () => import('@/shared/components/RadioInputBox.vue'),
    OrderBumpSearchBox: () => import('./OrderBumpSearchBox.vue'),
  },
})
export default class OrderBumpSelectOfferModal extends Vue {
  defaultOfferPayload: OfferPayload = {
    id: null,
    title: '',
    school_product_id: null,
    kind: OfferType.COMMON,
  };

  selectedOffer: OfferPayload | undefined = this.defaultOfferPayload;

  filteredItems: Array<OfferPayload> = [];

  @Prop({ required: true }) availableOffers: Array<OfferPayload>;
  @Prop() preSelectedOffer: OfferPayload;

  mounted() {
    this.setFilteredItems(this.availableOffers);
  }

  selectOffer() {
    this.$emit('select', this.selectedOffer);
    this.closeModal();
  }

  closeModal() {
    this.$bvModal.hide('order-bump-select-offer-modal');
  }

  imageURL(kind) {
    const image = {
      free: 'free',
      common: 'default',
      subscription: 'subscription',
    };

    return require(`@/assets/images/SparkMembers/OffersProduct/${image[kind]}.svg`);
  }

  updateSelectedOffer() {
    if (this.preSelectedOffer)
      this.selectedOffer = this.availableOffers.find(availableOffer => availableOffer.id === this.preSelectedOffer.id);
  }

  setFilteredItems(items) {
    this.filteredItems = items;
  }

  @Watch('preSelectedOffer', { immediate: true })
  onPreSelectedOfferChange() {
    this.updateSelectedOffer();
  }

  @Watch('availableOffers', { immediate: true })
  onAvailableOffersChange() {
    this.updateSelectedOffer();
  }
}
